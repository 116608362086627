import React, { useState, useContext } from 'react';
import { BiShoppingBag } from "react-icons/bi";
import { CartContext } from './CartContext';
import '../assets/styles/AddToCart.css';
const AddToCartButton = ({ productId, quantity = 1 }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { updateCartCount } = useContext(CartContext);
  const handleAddToCart = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/cart/add`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include', // Here's the magic for sending cookies with fetch
        body: JSON.stringify({ productId, quantity }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to add product to cart');
      }
      // Handle success, e.g., showing a success message or updating cart state
      const data = await response.json(); // Assuming the API returns the updated cart count
      updateCartCount(data.newCartItemCount); 
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <button 
      onClick={handleAddToCart}
      className="addtocartbtn flex items-center justify-center bg-red-900 text-white duration-100 hover:bg-red-800"
      disabled={loading}
    >
      <BiShoppingBag className="mx-2" />
      {loading ? 'Adding...' : 'Add to Cart'}
    </button>
  );
};

export default AddToCartButton;