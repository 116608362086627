import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import { FaAngleDown } from "react-icons/fa";
import { FaAngleUp } from "react-icons/fa";
import "../assets/styles/Category.css";

const HeaderCategory = () => {
  const [openDropdown, setOpenDropdown] = useState(null);
  const [categories, setCategory] = useState([]);
  const [error, setError] = useState(null);
  const dropdownRefs = useRef([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/productCategory/getAll`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({}),
        });
    
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.status}`);
        }
    
        const data = await response.json();
        setCategory(data);
      } catch (error) {
        console.error('Fetch error:', error);
        setError(error.message);
      }
    };

    fetchCategories();
  }, []);

  const toggleDropdown = (categoryName) => {
    setOpenDropdown((prevCategoryName) =>
      prevCategoryName === categoryName ? null : categoryName
    );
  };

  const handleClickOutside = (event) => {
    if (dropdownRefs.current.every(ref => ref && !ref.contains(event.target))) {
      setOpenDropdown(null);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const setDropdownRef = (el) => {
    if (el && !dropdownRefs.current.includes(el)) {
      dropdownRefs.current.push(el);
    }
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="category-slider">
      {error && <div className="error-message">{error}</div>}
      {categories.length > 0 ? (
        <Slider {...settings}>
          {categories.map((cat, index) => (
            <div
              className="items"
              key={index}
              ref={setDropdownRef}
            >
               <a
                href={cat.productTypes.length ==1 ? `/products/${cat.productTypes[0].slug}` : "#"}
                onClick={(event) => {
                  if (cat.productTypes.length > 1) {
                    event.preventDefault();
                    toggleDropdown(cat.name);
                  }
                }}
              >
              <div className="catImg">
                <img src={cat.image} alt={cat.name} />
              </div>
             
                {cat.name}
                {cat.productTypes.length > 1 && (
                  <span className="dropdown-icon"  onClick={(event) => {
                    event.stopPropagation();
                    toggleDropdown(cat.name);
                  }} >
                    {openDropdown === cat.name ? <FaAngleUp /> : <FaAngleDown/>}
                  </span>
                )}
              </a>
              {cat.productTypes.length > 1 && openDropdown === cat.name && (
                <div
                  className="dropdown-content"
                  // onMouseLeave={() => setOpenDropdown(null)}
                >
                  {cat.productTypes.map((ptype) => (
                    <a href={`/products/${ptype.slug}`} key={ptype.id}>
                      {ptype.name}
                    </a>
                  ))}
                </div>
              )}
            </div>
          ))}
        </Slider>
      ) : (
        <p>Loading categories...</p>
      )}
    </div>
  );
};

export default HeaderCategory;
