import React from 'react';
import HeaderContainer from '../components/HeaderContainer';
import FooterContainer from '../components/FooterContainer';
import HeaderCategory from '../components/HeaderCategory';
import '../assets/styles/ProductDetails.css'
import { CartProvider } from './CartContext';
import RefundPolicy from './RefundPolicy';
const PolicyPage = () => {


  return (
    <CartProvider>
        <HeaderContainer />
        <HeaderCategory />
        <RefundPolicy  />
        <FooterContainer />
    </CartProvider>
  );
};

export default PolicyPage;
