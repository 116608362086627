import React, { createContext, useState } from 'react';

// Create a context for the cart
export const CartContext = createContext();

// Cart provider component
export const CartProvider = ({ children }) => {
  const [cartItemCount, setCartItemCount] = useState(0);

  // Function to update cart count
  const updateCartCount = (count) => {
    setCartItemCount(count);
  };

  return (
    <CartContext.Provider value={{ cartItemCount, updateCartCount }}>
      {children}
    </CartContext.Provider>
  );
};
