import React, { useState, useEffect } from 'react';
import '../assets/styles/ProductSlider.css';
import Slider from 'react-slick';
import useDeviceType from './UseDeviceType';
import AddToCartButton from './AddToCartButton';

const ProductSlider = ({ title,slug }) => {
  const [products, setProducts] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch data from the backend
    const fetchProducts = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/curatedProduct/getAll`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ "slug": slug }),
        });

        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.status}`);
        }

        const data = await response.json();
        setProducts(data[0].products);
      } catch (error) {
        console.error('Fetch error:', error);
        setError(error.message);
      }
    };

    fetchProducts();
  }, []);

  const isMobile = useDeviceType();
  const numProducts = products.length;

  const settings = {
  infinite: false,
  slidesToShow: Math.min(numProducts, isMobile ? 1.5 : 4.5),
  slidesToScroll: 1,
  centerMode: false,
  focusOnSelect: true,
  responsive: [
    {
      breakpoint: 1024, // For tablets and small laptops
      settings: {
        slidesToShow: Math.min(numProducts, 3.5),
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768, // For mobile
      settings: {
        slidesToShow: Math.min(numProducts, 1.1),
        slidesToScroll: 1,
        centerMode: false,
      },
    },
  ],
};
  return (
    <div className="slider container py-4">
      <h2 className="centered-heading">{title}</h2>
      <div className="productSlider">
        <Slider {...settings}>
          {products.map((prdct, index) => (
            <div className="slider-wrapper" key={index}>
              <div className="productBox">
                <div className="productImage">
                  <a href={`/product/${prdct.slug}`}>
                    <img src={prdct.images[0].url} alt={prdct.name} />
                  </a>
                </div>
                <div className="productTitle">{prdct.name}</div>
                <div className="productDesc">{prdct.shortDesc}</div>
                <div className="productPrice">₹{prdct.variants[0].price}</div>
                <AddToCartButton productId={prdct.id} quantity={1} />
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default ProductSlider;
