import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBTypography, MDBIcon } from 'mdb-react-ui-kit';

const WhyWeStartedSection = () => {
  return (
    <MDBContainer className="text-center py-4" style={{ backgroundColor: '#f8f9fa', borderRadius: '8px' }}>
      <MDBRow className="justify-content-center mb-4">
        <MDBCol lg="8">
          <MDBTypography tag="h2" className="mb-4 text-danger centered-heading" style={{ fontWeight: 'bold' }}>
            Why We Started BombaeDuck
          </MDBTypography>
          <MDBTypography tag="p" className="lead text-muted mb-4">
            As fellow Mumbaikars, we understand the daily hustle of city life—the long hours, the traffic, the constant rush. But no matter how busy life gets, one thing remains non-negotiable: the need for fresh, healthy, and genuine food.
          </MDBTypography>
          <MDBTypography tag="p" className="lead text-muted mb-4">
            We didn’t just want to solve this problem for ourselves, but for every household that values authenticity and premium quality in their food. BombaeDuck was born out of this desire—our passion to bring farm-fresh, hormone-free, antibiotic-free, and custom-cut products directly to your door.
          </MDBTypography>
          <MDBTypography tag="p" className="lead text-muted mb-4">
            Whether it’s ensuring that every item is custom-cut just the way you like, or guaranteeing a 90-minute delivery, we’re committed to making your life easier while keeping quality at the forefront. With BombaeDuck, you can rest assured that you're getting the very best—straight from farm to fork.
          </MDBTypography>
        </MDBCol>
      </MDBRow>
      
    </MDBContainer>
  );
};

export default WhyWeStartedSection;
