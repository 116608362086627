import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardImage, MDBCardBody, MDBTypography } from 'mdb-react-ui-kit';

const images = [
  { src: '/images/galary1.jpg', title: 'Fresh Seafood' },
  { src: '/images/galary2.jpg', title: 'Custom Cuts' },
  { src: '/images/galary3.jpg', title: 'Farm Fresh' },
  { src: '/images/galary4.jpg', title: 'Healthy Ingredients' },
  { src: '/images/galary1.jpg', title: 'Sustainable Practices' },
  { src: '/images/galary2.jpg', title: 'Sustainable Practices' },
  { src: '/images/galary3.jpg', title: 'Farm Fresh' },
  { src: '/images/galary1.jpg', title: 'Sustainable Practices' },


];

const ImageGallery = () => {
  return (
    <MDBContainer className="py-4">
    <MDBTypography tag="h2" className="text-center mb-4 centered-heading">Our Products Gallery</MDBTypography>
    <MDBRow>
      {images.map((image, index) => (
        <MDBCol key={index} xs="6" sm="6" md="4" lg="3" className="mb-4"> {/* 2 columns on mobile, 3 on medium, 4 on large */}
          <MDBCard>
            <MDBCardImage src={image.src} position="top" alt={image.title} />
            <MDBCardBody>
              <MDBTypography tag="h5" className="text-center">{image.title}</MDBTypography>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      ))}
    </MDBRow>
  </MDBContainer>
  );
};

export default ImageGallery;
