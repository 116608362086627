import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBTypography } from 'mdb-react-ui-kit';
import { CartProvider } from './CartContext';
import HeaderContainer from './HeaderContainer';
import HeaderCategory from './HeaderCategory';
import FooterContainer from './FooterContainer';

const AboutUs = () => {
  return (
    <CartProvider>
    <HeaderContainer />
    <HeaderCategory />
    <MDBContainer className='my-5'>
      <MDBRow>
        <MDBCol md='12'>
          <MDBCard>
            <MDBCardBody>
              <MDBTypography tag='h2' className='text-center mb-4'>
                About Us
              </MDBTypography>
              <MDBTypography tag='p' className='lead'>
                At Bombae Duck, our mission is to redefine excellence in the seafood industry by offering the freshest and finest quality products, sourced from the pristine waters of the Indian coast. We are committed to maintaining the highest standards through our state-of-the-art backward-integrated farms and aquaculture facilities, ensuring the premium quality of every product we deliver.
              </MDBTypography>
              <MDBTypography tag='p'>
                Our facilities are equipped with cutting-edge technology to process and package seafood with the utmost care, adhering to rigorous hygiene and food safety standards. Sustainability is at the core of our operations, as we strive to minimize waste and conserve resources throughout our production process.
              </MDBTypography>
              <MDBTypography tag='p'>
                From fresh, never-frozen seafood, chicken, and mutton to frozen raw and ready-to-cook items, our diverse range is designed to enhance your culinary experience. Our marinated seafood, infused with classic flavors, is crafted to bring you gourmet-quality meals with minimal preparation time.
              </MDBTypography>
              <MDBTypography tag='p'>
                What sets Bombae Duck apart is our commitment to transparency. We encourage customers to visit our stores, where they can touch, feel, and see the quality of our products firsthand.
              </MDBTypography>
              <MDBTypography tag='p'>
                Join our growing community of discerning customers who trust Bombae Duck for their seafood and meat needs. Experience the refined flavors and superior quality that have made us a trusted name in the industry.
              </MDBTypography>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
    <FooterContainer />
    </CartProvider>
  );
};

export default AboutUs;
