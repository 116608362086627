import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import HeroBanner from '../components/HeroBanner';
import ProductSlider from '../components/ProductSlider';
import HeaderContainer from '../components/HeaderContainer';
import FooterContainer from '../components/FooterContainer';
import HeaderCategory from '../components/HeaderCategory';
import { CartProvider } from '../components/CartContext';
import BusinessQualitySection from './BusinessQualitySection';
import WhyWeStartedSection from './WhyWeStartedSection';
import ImageGallery from './ImageGallery';
const HomePage = () => {
  const [cartItemCount, setCartItemCount] = useState(0);
  const [error, setError] = useState(null);

  return (
    <CartProvider>
        <HeaderContainer  />
        <HeaderCategory />
        <HeroBanner  />
        <ProductSlider title="In the Spotlight" slug="spotlight" />
        <BusinessQualitySection />
        <WhyWeStartedSection />
        <ImageGallery />
        <FooterContainer />
    </CartProvider>
  );
};

export default HomePage;
