import React from 'react';
import HeaderContainer from '../components/HeaderContainer';
import FooterContainer from '../components/FooterContainer';
import HeaderCategory from '../components/HeaderCategory';
import ProductDetails from './ProductDetail';
import '../assets/styles/ProductDetails.css'
import ProductSlider from './ProductSlider';
import { CartProvider } from './CartContext';
const ProductPage = () => {


  return (
    <CartProvider>
        <HeaderContainer />
        <HeaderCategory />
        <ProductDetails  />
        <ProductSlider title="In the Spotlight"/>
        <FooterContainer />
    </CartProvider>
  );
};

export default ProductPage;
