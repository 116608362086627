import { AiOutlineHeart } from "react-icons/ai";
import { BiShoppingBag } from "react-icons/bi";
import ReactImageGallery from "react-image-gallery";
import React, { useState, useEffect } from 'react';
import Rater from "react-rater";
import "react-rater/lib/react-rater.css";
import '../assets/styles/ProductDetails.css'
import AddToCartButton from './AddToCartButton';
import { useParams } from 'react-router-dom';
const ProductDetail = () => {
  const [productDetailItem, setProduct] = useState([]);
  const [error, setError] = useState(null);
  const { slug } = useParams();
  useEffect(() => {
    // Fetch data from the backend
    const fetchProducts = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/product/${slug}`, {
          method: 'POST', // Ensure this is set to POST
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({"slug":"spotlight"}),
        });
    
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.status}`);
        }
    
        const data = await response.json();
        setProduct(data.product[0]);
      } catch (error) {
        console.error('Fetch error:', error);
        setError(error.message);
      }
    };
     

    fetchProducts();
  }, [slug]);
  const galleryImages = productDetailItem.images?.map(image => ({
    original: image.url,
    thumbnail: image.url, // You can use a separate field for thumbnail if available
    description: image.alt
  })) || [];
  const plusMinuceButton =
    "flex h-8 w-8 cursor-pointer items-center justify-center border duration-100 hover:bg-neutral-100 focus:ring-2 focus:ring-gray-500 active:ring-2 active:ring-gray-500";
  return (
    <section className="container flex-grow mx-auto max-w-[1200px] border-b py-5 lg:grid lg:grid-cols-2 lg:py-10">
      {/* image gallery */}
      <div className="container mx-auto px-4">
        <ReactImageGallery
          showBullets={false}
          showFullscreenButton={false}
          showPlayButton={false}
          items={galleryImages}
        />

        {/* /image gallery  */}
      </div>
      {/* description  */}

      <div className="mx-auto px-5 lg:px-5">
        <h2 className="pt-3 text-2xl font-bold lg:pt-0">
          {productDetailItem.name}
        </h2>
        <div className="mt-1">
          <div className="flex items-center">
            <Rater
              style={{ fontSize: "20px" }}
              total={5}
              interactive={false}
              rating={3.5}
            />

            <p className="ml-3 text-sm text-gray-400">
              ({productDetailItem.reviews})
            </p>
          </div>
        </div>
        <p className="mt-5 font-bold">
          Net:{" "}
          <span className="text-green-600">{productDetailItem.netgram}</span>
        </p>
        <p className="font-bold">
          No of pieces: <span className="font-normal">{productDetailItem.pieces}</span>
        </p>
        <p className="font-bold">
          Serves:{" "}
          <span className="font-normal">{productDetailItem.serves}</span>
        </p>
        
        <p className="mt-4 text-4xl font-bold text-violet-900">
          ₹{productDetailItem.variants?.[0]?.price}
        
        </p>
        <p className="pt-5 text-sm leading-5 text-gray-500">
          {productDetailItem.description}
        </p>
   
      
        <div className="mt-6">
          <p className="pb-2 text-xs text-gray-500">Quantity</p>
          <div className="flex">
            <button className={`${plusMinuceButton}`}>−</button>
            <div className="flex h-8 w-8 cursor-text items-center justify-center border-t border-b active:ring-gray-500">
              1
            </div>
            <button className={`${plusMinuceButton}`}> +</button>
          </div>
        </div>
        <div className="mt-7 flex flex-row items-center gap-6">
        <AddToCartButton productId={productDetailItem.id} quantity={1} />
         
        </div>
      </div>
    </section>
  );
};

export default ProductDetail;