import React, { useState, useEffect } from 'react';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBIcon,
  MDBRipple,
  MDBBtn,
} from "mdb-react-ui-kit";
import { Link } from 'react-router-dom';
import "../assets/styles/ProductList.css";

function ProductList({filterParam }) {
  const [products, setProducts] = useState([]);
  const [error, setError] = useState(null);
  useEffect(() => {
    // Fetch data from the backend
    const fetchProducts = async () => {
      try {
        let apiUrl = `${process.env.REACT_APP_API_URL}/product/getAll`;
        // if (filterParam) {
        //   apiUrl += `?slug=${filterParam}`;
        // }
        const response = await fetch(apiUrl, {
          method: 'POST', // Ensure this is set to POST
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({"slug":filterParam}),
        });
    
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.status}`);
        }
    
        const data = await response.json();
        setProducts(data);
      } catch (error) {
        console.error('Fetch error:', error);
        setError(error.message);
      }
    };
     

    fetchProducts();
  }, []);
  return (
    <MDBContainer fluid>
       {products.map((prdct, index) => (
      <MDBRow className="justify-content-center mb-0">
        <MDBCol md="12" xl="10">
          <MDBCard className="shadow-0 border rounded-3 mt-5 mb-3">
            <MDBCardBody>
              <MDBRow>
                <MDBCol md="12" lg="3" className="mb-4 mb-lg-0">
                  <MDBRipple
                    rippleColor="light"
                    rippleTag="div"
                    className="bg-image rounded hover-zoom hover-overlay"
                  >
                    <MDBCardImage
                      src={prdct.images[0].url}
                      fluid
                      className="w-100"
                    />
                    <a href="#!">
                      <div
                        className="mask"
                        style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}
                      ></div>
                    </a>
                  </MDBRipple>
                </MDBCol>
                <MDBCol md="6">
                  <h5>{prdct.name}</h5>
                  {/* <div className="d-flex flex-row">
                    <div className="text-danger mb-1 me-2">
                      <MDBIcon fas icon="star" />
                      <MDBIcon fas icon="star" />
                      <MDBIcon fas icon="star" />
                      <MDBIcon fas icon="star" />
                    </div>
                    <span>310</span>
                  </div> */}
                  {/* <div className="mt-1 mb-0 text-muted small">
                    <span>100% cotton</span>
                    <span className="text-primary"> • </span>
                    <span>Light weight</span>
                    <span className="text-primary"> • </span>
                    <span>
                      Best finish
                      <br />
                    </span>
                  </div> */}
                  {/* <div className="mb-2 text-muted small">
                    <span>Unique design</span>
                    <span className="text-primary"> • </span>
                    <span>For men</span>
                    <span className="text-primary"> • </span>
                    <span>
                      Casual
                      <br />
                    </span>
                  </div> */}
                  <p className="text-truncate mb-4 mb-md-0">
                    {prdct.shortDesc}
                  </p>
                </MDBCol>
                <MDBCol
                  md="6"
                  lg="3"
                  className="border-sm-start-none border-start"
                >
                  <div className="d-flex flex-row align-items-center mb-1">
                    <h4 className="mb-1 me-1">₹{prdct.variants[0].price}</h4>
                    {/* <span className="text-danger">
                      <s>$20.99</s>
                    </span> */}
                  </div>
                  <h6 className="text-success">Free shipping</h6>
                  <div className="d-flex flex-column mt-4">
                    <MDBBtn href={`/product/${prdct.slug}`} color="primary" size="sm">
                    
                      Details
                      
                    </MDBBtn>
                   
                  </div>
                </MDBCol>
              </MDBRow>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
      ))}
    </MDBContainer>
  );
}

export default ProductList;