import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';

const Terms = () => {
  return (
    <MDBContainer className="mt-5">
      <MDBRow className="justify-content-center">
        <MDBCol md="8">
          <h1 className="text-center mb-4">Terms & Conditions</h1>
          
          <p><strong>TERMS OF USE:</strong> By accessing or using the Bombae Duck website, you agree to be bound by these Terms and Conditions, as well as our Privacy Policy, which is incorporated herein by reference.</p>

          <p><strong>PRODUCT AVAILABILITY:</strong> All products and services offered by Bombae Duck are subject to availability. We reserve the right to limit the quantity of any product or service we offer or discontinue its sale at any time.</p>

          <p><strong>PRICING:</strong> All prices listed on our website are subject to change without prior notice. We reserve the right to change or modify prices, products, and services at any time.</p>

          <p><strong>PAYMENT:</strong> We accept payment through various modes such as credit cards, debit cards, UPI, and cash on delivery. Please note that some payment methods may not be available in certain areas.</p>

          <p><strong>90 MINUTES DELIVERY:</strong> We will deliver the products to the address specified by you during the ordering process. Delivery times may vary depending on your location and other factors beyond our control. We will make every effort to deliver the products on time but cannot be held liable for any delays caused by third-party delivery services or other factors beyond our control.</p>

          <p><strong>RETURN POLICY:</strong> We accept cancellations 1 hour prior to the delivery time selected by you. In order to cancel your order please call. We take the utmost care to provide the best product to our customers. However, if you find that the products are damaged or have any quality issues, immediately report this at the time of delivery. We will replace it for you at the next earliest delivery time or day. Any claims thereafter would not be entertained. We will not be able to replace seafood, chicken, or meat that is marinated or cooked at your end.</p>

          <p><strong>INTELLECTUAL PROPERTY:</strong> The content, trademarks, logos, and other intellectual property displayed on our website are owned by Bombae Duck or our licensors. You may not use any of our intellectual property without our prior written consent.</p>

          <p><strong>COMMUNICATION PREFERENCES:</strong></p>
          <ul>
            <li><strong>Account Notifications:</strong> Once you register on Bombae Duck, a service provided by Xanadu Fine Foodstuff LLP, you may receive occasional SMS/RCS or email notifications related to your account and transactions.</li>
            <li><strong>Marketing Updates:</strong> You might also receive notifications about our latest promotions and marketing activities on Bombae Duck. By registering and providing your contact details, you agree to receive these updates via call, SMS/RCS, email, WhatsApp or other digital channels.</li>
            <li><strong>Service Notifications:</strong> We may send notifications and reminders regarding Bombae Duck services. While we strive to deliver these promptly, we cannot guarantee their delivery and will not be held responsible for any missed notifications.</li>
            <li><strong>Opting Out:</strong> If you prefer not to receive marketing communications, newsletters, or other notifications from Bombae Duck, you can unsubscribe at any time. Instructions for opting out will be included in the communications you receive.</li>
            <li><strong>Call Recording:</strong> Please note that for quality assurance and training purposes, calls made to or by Bombae Duck may be recorded and monitored.</li>
          </ul>

          <p>If you have any questions or concerns about these Terms and Conditions or our products and services, please feel free to contact our customer support.</p>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}

export default Terms;
