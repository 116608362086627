import React from 'react';
import HeaderContainer from '../components/HeaderContainer';
import FooterContainer from '../components/FooterContainer';
import HeaderCategory from '../components/HeaderCategory';
import '../assets/styles/ProductDetails.css'
import ProductList from './ProductList';
import { CartProvider } from '../components/CartContext';
import { useParams } from 'react-router-dom';
const ProductListPage = () => {

  const { filterParam } = useParams();
  return (
    <CartProvider>
        <HeaderContainer />
        <HeaderCategory />
        <ProductList filterParam={filterParam} />
        <FooterContainer />
    </CartProvider>
  );
};

export default ProductListPage;
