import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import ProductPage from './components/ProductPage';
import HomePage from './components/HomePage';
import ProductListPage from './components/ProductListPage';
import CheckoutPage from './components/CheckoutPage';
import TermsPage from './components/TermsPage';
import PolicyPage from './components/PolicyPage';
import AboutUs from './components/AboutUs';

const App = () => {


  return (
     <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/product/:slug" element={<ProductPage />} />
        <Route path="/products" element={<ProductListPage />} />
        <Route path="/products/:filterParam?" element={<ProductListPage />} />
        <Route path="/checkout" element={<CheckoutPage />} />
        <Route path="/terms" element={<TermsPage />} />
        <Route path="/policy" element={<PolicyPage />} />
        <Route path="/about-us" element={<AboutUs />} />
      </Routes>
    </Router>
  );
};

export default App;
