import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';

const RefundPolicy = () => {
  return (
    <MDBContainer className="mt-5">
      <MDBRow className="justify-content-center">
        <MDBCol md="8">
          <h1 className="text-center mb-4">Privacy Policy</h1>

          <p><strong>Disclaimer:</strong> Please note that Bombae Duck  reserves the right to unilaterally modify this Policy at any time without prior notice. Such modifications may be required to ensure compliance with relevant laws and regulations or to accommodate changes within the Company's structure. We encourage you to regularly check this page for any updates.</p>

          <p><strong>Consent:</strong> By accessing or using our website, you agree to be bound by the terms and conditions of our Privacy Policy and consent to the collection, use, and disclosure of your personal information in accordance with it.</p>

          <h3>Welcome to www.cambaytiger.com!</h3>
          <p>Your privacy is important to us, and we're committed to safeguarding your personal information. Our Privacy Policy will tell you all you need to know, including the measures we've taken to keep your data secure. Read on to discover how we collect, use, and share your personal information.</p>

          <h3>What Type of Information Does Bombae Duck  Collect?</h3>
          <p>We understand the importance of protecting your privacy and we are committed to ensuring that any information we collect from you is used in accordance with applicable laws and regulations. Below is a detailed list of the types of information we may collect:</p>

          <ul>
            <li><strong>Personal Information:</strong> Includes your name, postal address, phone number, WhatsApp contact, and email address. If you create a Bombae Duck Rewards account, we may collect your username, password, and more.</li>
            <li><strong>Financial Information:</strong> Such as credit/debit card numbers and other payment information.</li>
            <li><strong>Purchase Information:</strong> Data about what, where, and how frequently you buy from us.</li>
            <li><strong>Survey and Interaction Information:</strong> Demographic information from surveys and other interactions.</li>
            <li><strong>Website Use Information:</strong> Automatically collected data like your browser type, IP address, and website activity.</li>
          </ul>

          <h3>How Does Bombae Duck Use Personal Information?</h3>
          <p>Your personal information is used for various purposes including research, responding to inquiries, confirming orders, delivering products, marketing communications, and customer service.</p>

          <h3>Sharing Personal Information with Third Parties</h3>
          <p>The company may share personal information in limited ways, such as with credit card processors, website hosts, or entities that help us market our products. We may also share data in legal circumstances, to protect our rights, or during a merger or acquisition.</p>

          <h3>Your Rights Regarding Your Information</h3>
          <p>You can request updates, changes, or the deletion of your personal information. You may opt out of promotional communications, but non-promotional emails about your account or business transactions may still be sent. You can adjust your browser’s cookie settings as well.</p>

          <h3>How We Safeguard Your Personal Data</h3>
          <p>We take reasonable security measures to protect your data, both physically and electronically. However, we cannot guarantee that all data transmissions over the internet are 100% secure, and we disclaim responsibility for any illegal access to our website.</p>

          <p>Explore our platform with confidence – your privacy is our priority!</p>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}

export default RefundPolicy;
