import React, { useState, useContext } from 'react';
import {
  MDBBtn,
  MDBCardImage,
  MDBCol,
  MDBRow,
  MDBTypography,
  MDBInput,
  MDBIcon,
} from 'mdb-react-ui-kit';
import { CartContext } from './CartContext';

const CartItem = ({ item }) => {
  const { updateCartCount } = useContext(CartContext);
  const [quantity, setQuantity] = useState(item.quantity);

  const handleIncrease = () => {
    setQuantity(prevQuantity => prevQuantity + 1);
    updateCartCount(quantity + 1); // Update the cart count if needed
  };

  const handleDecrease = () => {
    if (quantity > 0) {
      setQuantity(prevQuantity => prevQuantity - 1);
      updateCartCount(quantity - 1); // Update the cart count if needed
    }
  };

  return (
    <MDBRow className="mb-4 d-flex justify-content-between align-items-start flex-column flex-md-row">
      <MDBCol md="2" lg="2" xl="2" className="text-center mb-2 mb-md-0">
        <MDBCardImage
          src={item.imageUrl}
          fluid
          className="rounded-3"
          alt={item.productName}
        />
      </MDBCol>

      <MDBCol md="8" lg="8" xl="8">
        <MDBTypography tag="h6" className="text-muted">
          {item.productType}
        </MDBTypography>
        <MDBTypography tag="h6" className="text-black mb-0">
          {item.productName}
        </MDBTypography>

        <MDBTypography tag="h6" className="mb-2">
          ₹ {item.price * quantity}
        </MDBTypography>

        <div className="d-flex align-items-center">
          <MDBBtn color="link" className="px-2" onClick={handleDecrease}>
            <MDBIcon fas icon="minus" />
          </MDBBtn>
          <MDBInput
            type="number"
            min="0"
            value={quantity}
            size="sm"
            className="mx-2 w-15"
            style={{ width: '50px' }}
            readOnly
          />
          <MDBBtn color="link" className="px-2" onClick={handleIncrease}>
            <MDBIcon fas icon="plus" />
          </MDBBtn>
          <a href="#!" className="text-danger mx-2">
            <MDBIcon fas icon="trash" />
          </a>
        </div>
      </MDBCol>

      <hr className="my-4" />
    </MDBRow>
  );
};

export default CartItem;
