import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBCardText,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBInput,
  MDBRow,
  MDBTypography,
} from "mdb-react-ui-kit";
import React, { useState, useEffect, useContext } from 'react';
import "../assets/styles/Checkout.css";
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import { CartContext } from './CartContext';
import CartItem from "./CartItem";

export default function Checkout() {
  const { cartItemCount } = useContext(CartContext);
  const [cartItems, setCartItems] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { updateCartCount } = useContext(CartContext);
  // Function to fetch cart items
  const fetchCartItems = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/cart/items`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch cart items');
      }

      const data = await response.json();
      setCartItems(data.items); 
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCartItems();
  }, []);

  const totalPrice = cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0);

  return (
    <section className="h-100 h-custom inline" style={{ backgroundColor: "#eee" }}>
      <MDBContainer className="py-5 h-100">
        <MDBRow className="justify-content-center align-items-center h-100">
          <MDBCol size="12">
            <MDBCard className="card-registration card-registration-2" style={{ borderRadius: "15px" }}>
              <MDBCardBody className="p-0">
                <MDBRow className="g-0">
                  <MDBCol lg="8">
                    {loading && <p>Loading cart items...</p>}
                    {!loading && cartItems.length > 0 && (
                      <div className="p-5">
                        <div className="d-flex justify-content-between align-items-center mb-5">
                          <MDBTypography tag="h1" className="fw-bold mb-0 text-black">
                            Shopping Cart
                          </MDBTypography>
                          <MDBTypography className="mb-0 text-muted">
                            {cartItemCount} items
                          </MDBTypography>
                        </div>
                        <hr className="my-4" />
                        {cartItems.map((item) => (
                          <CartItem key={item.id} item={item} />
                        

                        ))}

                        <div className="pt-5">
                          <MDBTypography tag="h6" className="mb-0">
                            <MDBCardText tag="a" href="#!" className="text-body">
                              <MDBIcon fas icon="long-arrow-alt-left me-2" /> Back to shop
                            </MDBCardText>
                          </MDBTypography>
                        </div>
                      </div>
                    )}

                    {!loading && cartItems.length === 0 && <p>Your cart is empty.</p>}
                  </MDBCol>
                  <MDBCol lg="4" className="bg-grey">
                    <div className="p-5">
                      <MDBTypography tag="h3" className="fw-bold mb-5 mt-2 pt-1">
                        Summary
                      </MDBTypography>

                      <MDBTypography tag="h5" className="text-uppercase mb-3">
                        Shipping Address
                      </MDBTypography>
                      <MDBInput label="Full Name" size="lg" className="mb-3" />
                      <MDBInput label="Address" size="lg" className="mb-3" />
                      <MDBInput label="City" size="lg" className="mb-3" />
                      <MDBInput label="Postal Code" size="lg" className="mb-3" />
                      <MDBInput label="Country" size="lg" className="mb-3" />

                      <hr className="my-4" />

                      <div className="d-flex justify-content-between mb-4">
                        <MDBTypography tag="h5" className="text-uppercase">
                          items {cartItemCount}
                        </MDBTypography>
                        <MDBTypography tag="h5">₹ {totalPrice}</MDBTypography>
                      </div>

                      <MDBTypography tag="h5" className="text-uppercase mb-3">
                        Shipping
                      </MDBTypography>

                      <div className="mb-4 pb-2">
                        <select className="select p-2 rounded bg-grey" style={{ width: "100%" }}>
                          <option value="1">Standard Delivery - ₹50.00</option>
                          <option value="2">Express Delivery - ₹100.00</option>
                        </select>
                      </div>

                  
                      <hr className="my-4" />

                      <div className="d-flex justify-content-between mb-5">
                        <MDBTypography tag="h5" className="text-uppercase">
                          Total price
                        </MDBTypography>
                        <MDBTypography tag="h5">
                          ₹ {totalPrice +50}
                        </MDBTypography>
                      </div>

                      <MDBBtn color="dark" block size="lg">
                        Proceed to Payment
                      </MDBBtn>
                    </div>
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </section>
  );
}
