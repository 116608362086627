import React, { useState, useEffect , useContext } from 'react';
import { BiShoppingBag } from "react-icons/bi";
import { FaShoppingBag } from "react-icons/fa";
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import '../assets/styles/HeaderContainer.css';
import { CartContext } from './CartContext';
const HeaderContainer = () => {
  const { cartItemCount, updateCartCount } = useContext(CartContext);
  useEffect(() => {
    // Function to fetch cart count from the API initially
    const fetchCartCount = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/cart/count`, {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        
        if (!response.ok) {
          throw new Error('Failed to fetch cart count');
        }

        const data = await response.json();
        updateCartCount(data.count);
      } catch (error) {
        console.error(error.message);
      }
    };

    // Fetch cart count when the header is loaded
    fetchCartCount();
  }, [updateCartCount]);
  return (
    <header className="bg-white-800 text-white container flex items-center justify-between">
      {/* Logo */}
      <div className="flex items-center">
        <Link to="/" className="flex items-center logobox">
          <img
            src="/images/abc.png" // Replace with your logo URL
            alt="Logo" className="logo"
          />
        </Link>
      </div>

      {/* Navigation and Cart Icon */}
      <nav className="flex items-center gap-4 p-4">
        {/* Cart Icon */}
        <Link to="/checkout" className="relative flex items-center text-xl hover:text-gray-300">
          <FaShoppingBag className="text-black"/>
          
          {cartItemCount > 0 && (
            <span className="absolute -top-2 -right-2 h-5 w-5 rounded-full bg-red-600 text-white flex items-center justify-center text-xs font-bold">
              {cartItemCount}
            </span>
          )}
        </Link>
      </nav>
    </header>
  );
};

export default HeaderContainer;