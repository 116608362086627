import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';

const BusinessQualitySection = () => {
  return (
    <MDBContainer className="text-center py-4">
      <h2 className="mb-4 centered-heading">Why Choose Us?</h2>

      <MDBRow className="mb-4">
        <MDBCol xs="12" sm="6" md="4" className="d-flex flex-column align-items-center mb-4">
          <MDBIcon icon="gem" size="3x" className="mb-3 text-danger" />
          <h5>Premium Quality</h5>
          <p className="text-center text-muted">We provide the highest quality, fresh seafood and meat, handpicked and processed with care.</p>
        </MDBCol>

        <MDBCol xs="12" sm="6" md="4" className="d-flex flex-column align-items-center mb-4">
          <MDBIcon icon="clock" size="3x" className="mb-3 text-danger" />
          <h5>90-Minute Delivery</h5>
          <p className="text-center text-muted">Get your orders delivered fresh within 90 minutes, right to your doorstep.</p>
        </MDBCol>

        <MDBCol xs="12" sm="6" md="4" className="d-flex flex-column align-items-center mb-4">
          <MDBIcon icon="cut" size="3x" className="mb-3 text-danger" />
          <h5>Fresh Custom Cuts</h5>
          <p className="text-center text-muted">We offer custom cuts to suit your preferences, ensuring freshness and satisfaction.</p>
        </MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol xs="12" sm="6" md="4" className="d-flex flex-column align-items-center mb-4">
          <MDBIcon icon="leaf" size="3x" className="mb-3 text-danger" />
          <h5>No Hormones or Antibiotics</h5>
          <p className="text-center text-muted">Our products are free from harmful hormones and antibiotics, ensuring health and safety.</p>
        </MDBCol>

        <MDBCol xs="12" sm="6" md="4" className="d-flex flex-column align-items-center mb-4">
          <MDBIcon icon="truck" size="3x" className="mb-3 text-danger" />
          <h5>Farm to Fork</h5>
          <p className="text-center text-muted">Directly from our farms to your table, guaranteeing freshness and quality in every bite.</p>
        </MDBCol>
      </MDBRow>
      
    </MDBContainer>
    
  );
};

export default BusinessQualitySection;
